import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useConfig } from '../../../providers/ConfigProvider';

const useThumbtackInfo = (category, zip) => {
  const { IS_PROD } = useConfig();

  const categoryMapping = useMemo(
    () => ({
      APPLIANCES: {
        name: 'Appliance Repair or Maintenance',
        pk: '166573972257055145',
      },
      CABINETS_COUNTERTOPS: {
        name: 'Cabinet Installation',
        pk: '234489736316256728',
      },
      DOORS_WINDOWS: {
        name: 'Window Installation',
        pk: '166570397309092258',
      },
      ELECTRICAL: {
        name: 'Electrical and Wiring Repair',
        pk: '122769389996753250',
      },
      FENCING: {
        name: 'Fence and Gate Installation',
        pk: '167177250904949178',
      },
      FIREPLACE: {
        name: 'Fireplace and Chimney Cleaning or Repair',
        pk: '151453853833978139',
      },
      FLOORING: {
        name: 'Floor Installation or Replacement',
        pk: '151436204227846419',
      },
      GARAGE: {
        name: 'Garage Door Installation or Replacement',
        pk: '166575067142390188',
      },
      HEATING_AND_COOLING: {
        name: 'Central Air Conditioning Repair or Maintenance',
        pk: '166577475042034098',
      },
      INSPECTIONS: { name: 'Home Inspection', pk: '152053870920155482' },
      KEYS_LOCKS: {
        name: 'Lock Installation and Repair',
        pk: '135130438449717531',
      },
      LANDSCAPING: {
        name: 'Outdoor Landscaping and Design',
        pk: '124319844707721647',
      },
      PEST_CONTROL: {
        name: 'Pest Control Services',
        pk: '133665232699441654',
      },
      PLUMBING: { name: 'Plumbing Pipe Repair', pk: '150850272033989083' },
      POOL: {
        name: 'Swimming Pool Cleaning, Maintenance, and Inspection',
        pk: '151786726772498727',
      },
      ROOF: { name: 'Roof Repair or Maintenance', pk: '174455213291954651' },
      GENERAL: {
        name: 'General Contracting',
        pk: '114400570923630858',
      },
      OTHER: {
        name: 'General Contracting',
        pk: '114400570923630858',
      },
      HANDYMAN: { name: 'Handyman', pk: '109125193401647362' },
    }),
    [],
  );

  const categoryInfo = categoryMapping[category] || null;

  const iframeUrl = useMemo(() => {
    if (!categoryInfo) return null;

    const baseUrl = IS_PROD
      ? 'https://www.thumbtack.com/embed/pro-list'
      : 'https://staging-partner.thumbtack.com/embed/pro-list';
    const params = new URLSearchParams({
      category_pk: categoryInfo.pk,
      utm_source: 'cma-turbotenant',
      utm_campaign: 'maintenance_request_card',
      iframe_id: uuidv4(),
      limit: 10,
    });

    if (zip) {
      params.append('zip_code', zip);
    }

    return `${baseUrl}?${params.toString()}`;
  }, [categoryInfo]);

  return { category: categoryInfo, iframeUrl };
};

export default useThumbtackInfo;
