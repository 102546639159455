import React from 'react';
import { Icon as TTIcon } from '@tt/ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './VerticalListWithIcons.module.scss';

const VerticalListWithIcons = ({
  className,
  iconWrapperClassName,
  items,
  itemClassName,
}) => {
  const renderItem = ({ icon: Icon, iconProps, content }, key) => (
    <div className={cx(styles.item, itemClassName)} key={key}>
      <div className={cx(styles.iconWrapper, iconWrapperClassName)}>
        {typeof Icon === 'string' ? (
          <TTIcon name={Icon} {...iconProps} />
        ) : (
          Icon && <Icon className={styles.icon} {...iconProps} />
        )}
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  );

  return (
    <div className={cx(styles.container, className)}>
      {items.map(renderItem)}
    </div>
  );
};

VerticalListWithIcons.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.object,
        PropTypes.string,
      ]),
      iconProps: PropTypes.object,
      content: PropTypes.node,
    }),
  ).isRequired,
};

export default VerticalListWithIcons;
