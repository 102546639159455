export const Experiments = {
  GoodBetterBest: {
    name: 'experiment_gbb',
    variants: {
      A: 'A', // Tabs
      B: 'B', // Accordion
      C: 'C', // Toggle
      D: 'D', // Tabs Premium Plus 299
      E: 'E', // Tabs Premium Plus 249
      G: 'G',
      H: 'H',
      control: 'Control',
    },
  },
  LandlordPaysSpecificStates: {
    name: 'LL_Pays_NY_WI_MA_VT',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  LandlordPaysOtherStates: {
    name: 'LL_Pays_Other_States',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  MaintenanceRequestLulaProCardAug2024: {
    name: 'maintenance_request_lula_pro_card_aug_2024',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  RentPaymentsFlowOptimization: {
    name: 'rp_flow_optimization',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  LeaseAgreementCosigner: {
    name: 'product_lease_agreement_co-signer',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  PremiumModalGooglePay: {
    name: 'premium_checkout_modal_google_apple_pay_test',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  RenterCreditReportingPricing: {
    name: 'renter_credit_reporting_pricing',
    variants: {
      control: 'control',
      test_1: 'test_1',
      test_2: 'test_2',
    },
  },
  RenterCreditReportingOptInPromo: {
    name: 'renter_credit_reporting_opt_in_promo',
    variants: {
      control: 'control',
      test_1: 'test_1',
      test_2: 'test_2',
    },
  },
  CxScheduleProcess: {
    name: 'gt_cx_schedule_process',
    variants: {
      control: 'control',
      test: 'variant_1',
    },
  },
  LLPropertyAdd: {
    name: 'gt_ll_property_add',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  PaymentOnboardingUpsellPremium: {
    name: 'payment_onboarding_upsell_premium_exp',
    variants: {
      ach_form: 'ach_form',
      premium_card: 'premium_card',
      control: 'control',
    },
  },
  GtOnePropertyNllSignupOnboardingAnswers: {
    name: 'gt_1p_nll_signup_onboarding_answers',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  RRAnnualFlow: {
    name: 'renter_credit_annual_flow_experiment',
    variants: {
      control: 'control',
      variant_B: 'variant_B',
      variant_C: 'variant_C',
    },
  },
  ChargesFirst: {
    name: 'charges_first',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  GtOnboardingPhoneNumber: {
    name: 'gt_onboarding_phone_number',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  AvailableDateCopyTest: {
    name: 'available_date_copy_test',
    variants: {
      control: 'control',
      variant: 'variant',
    },
  },
  FirstApplicationPrice: {
    name: 'first_application_price',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
      variant_3: 'variant_3',
      variant_4: 'variant_4',
      variant_5: 'variant_5',
      variant_6: 'variant_6',
      variant_7: 'variant_7',
    },
  },
  GtAnimatedLogogardenMobile: {
    name: 'animated_logogarden_mobile',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  GtThreePropertiesNllSignupOnboardingAnswers: {
    name: 'gt_3p_signup_onboarding_answers',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
    },
  },
  MARKETING_LISTING_UPDATE_EXPERIMENT: {
    name: 'marketing_listing_update_experiment',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
      variant_3: 'variant_3',
      variant_4: 'variant_4',
    },
  },
  PaymentsGiftCardPromo: {
    name: '25_gift_card_promo_first_rent_payment',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  GtPropertyTypeShiftExperiment: {
    name: 'gt_property_type_shift',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  GtAlcLease: {
    name: 'gt_alc_lease',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
    },
  },
  FreeTrialCreditCardModal: {
    name: 'free_trial_credit_card_modal',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  AccountingAddonModal: {
    name: 'accounting_addon_modal',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  ListingPreview: {
    name: 'listing_preview',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  GtPlaningToBuy: {
    name: 'gt_planning_to_buy',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
    },
  },
  GtOaMultiSelect: {
    name: 'gt_oa_multi_select',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
      variant_3: 'variant_3',
    },
  },
  GT_PROPERTY_TYPE_SHIFT_EXPANDED: {
    name: 'gt_property_type_shift_expanded',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
      variant_2: 'variant_2',
      variant_3: 'variant_3',
    },
  },
  GT_HOW_DID_YOU_HEAR_ABOUT_US: {
    name: 'gt_how_did_you_hear_about_us',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  GT_PROPERTY_TYPE_SHIFT_SMALL: {
    name: 'gt_property_type_shift_small',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
  GT_FINISH_ADDING_RENTALS_CTA: {
    name: 'gt_finish_adding_rentals_cta',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
};
