import React, { useEffect, useState } from 'react';

import Card from '../../Card';
import Modal from '../../Modal';
import AccountingSuccessAnimation from '../../ReiHubSubscription/AccountingSuccessAnimation';
import { H3 } from '../../Typography';

import SuccessAnimation from './SuccessAnimation';

import styles from './PremiumSuccessState.module.scss';

const PremiumSuccessState = () => {
  const [message, setMessage] = useState(
    'You just leveled up your rental management!',
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage('Ready to do the same with your bookkeeping?');
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal open className={styles.modal} autoFocus={false}>
      <AccountingSuccessAnimation />
      <Card className={styles.successAnimationGifWrapper}>
        <div className={styles.content}>
          <SuccessAnimation style={{ width: 120 }} />
          <H3 className="mt-12">{message}</H3>
        </div>
      </Card>
    </Modal>
  );
};

export default PremiumSuccessState;
