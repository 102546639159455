const ALLOWED_EXTENSIONS = [
  'jpg',
  'png',
  'jpeg',
  'pdf',
  'zip',
  'xls',
  'xlsx',
  'txt',
  'csv',
  'doc',
  'docx',
];

export const UPLOAD_DOCUMENT_EXTENSIONS = [
  'docx',
  'doc',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'odt',
  'odp',
  'ods',
  'pdf',
  'txt',
  'png',
  'jpg',
  'txt',
  'zip',
];

export const UPLOAD_DOCUMENT_MAX_FILE_SIZE = 20971520; // 20MB

export default ALLOWED_EXTENSIONS;
