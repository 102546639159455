import React from 'react';
import { Link } from 'react-router-dom';
import { Icon as TTIcon } from '@tt/ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../../components/Spinner';

import styles from './IconButton.module.scss';

const IconButton = React.forwardRef(
  (
    {
      to,
      href,
      type = 'button',
      onClick,
      icon: Icon,
      iconProps = {},
      className,
      disabled,
      loading,
      ...otherProps
    },
    ref,
  ) => {
    if (loading) return <Spinner secondary />;

    const getElementNode = () => {
      if (onClick) return 'button';
      if (to && !disabled) return Link;
      if (href && !disabled) return 'a';
      return 'button';
    };

    // eslint-disable-next-line no-useless-assignment
    const Element = getElementNode();

    const elementProps = {
      className: cx(styles.button, { [styles.disabled]: disabled }, className),
      disabled,
      ref,
      ...otherProps,
      ...(onClick ? { onClick, type } : {}),
      ...(!onClick && to && !disabled ? { to } : {}),
      ...(!onClick && href && !disabled ? { href } : {}),
      ...(!onClick && !to && !href ? { type } : {}),
    };

    return (
      <Element {...elementProps}>
        {typeof Icon === 'string' ? (
          <TTIcon name={Icon} {...iconProps} />
        ) : (
          Icon && (
            <Icon
              {...iconProps}
              className={cx(styles.icon, iconProps.className)}
            />
          )
        )}
      </Element>
    );
  },
);

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]),
  iconProps: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default IconButton;
