import React from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import isiOSDevice from '../../../helpers/isIosDevice';
import IframeAnimation from '../../Animations/iOSIframeAnimation';

import successAnimationJson from './successAnimation.animation.json';

const SuccessAnimation = ({ style = {} }) => {
  const options = {
    path: successAnimationJson,
    loop: false,
    autoplay: true,
  };

  const LoaderData = useLottie(options, style);

  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <IframeAnimation
        style={style}
        src="https://lottie.host/embed/61e0da65-dc93-446e-806b-aa5b2a1fefdb/mpPzpJNWqd.json"
      />
    );
  }

  return LoaderData.View;
};

SuccessAnimation.propTypes = {
  style: PropTypes.object,
};

export default SuccessAnimation;
