import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import useOutsideClick from '@rooks/use-outside-click';
import cx from 'classnames';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import ownerSettingsQuery from '../../../graphql/queries/ownerSettingsQuery.graphql';
import { useRenderOutside } from '../../../helpers/render-outside';
// eslint-disable-next-line eslint-local-rules/no-imports-from
import ArrowRight from '../../../icons/ArrowRight';
// eslint-disable-next-line eslint-local-rules/no-imports-from
import CheckFilled from '../../../icons/CheckFilled';
// eslint-disable-next-line eslint-local-rules/no-imports-from
import CloseIcon from '../../../icons/streamline/Close';
// eslint-disable-next-line eslint-local-rules/no-imports-from
import MoreIcon from '../../../icons/streamline/MoreNavigation';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import Badge from '../../Badge';
import GlobalBanner from '../../Banners/GlobalBanner';
import Divider from '../../Divider';
import PremiumSubscriptionModal from '../../PremiumSubscription';
import { MODAL_LOCATIONS } from '../../PremiumSubscription/constants';
import ProfilePicture from '../../ProfilePicture';
import MenuOfferTag from '../MenuOfferTag/MenuOfferTag';

import styles from './MobileNavigation.module.scss';

const OWNER_ITEMS_PER_ROW = 4;

const MobileNavigation = ({
  location,
  isHidden,
  isOwner,
  className,
  unreadMessagesCount,
  ownerNavItems,
  renterNavItems,
  ownerDrawerItems,
  user,
  userDataLoaded,
  accounts,
  currentAccount,
}) => {
  const renderOutside = useRenderOutside();
  const containerRef = useRef();
  const navigate = useNavigate();
  const config = useConfig();
  const [isOpen, setIsOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);

  const isIOSChrome =
    /CriOS/i.test(navigator?.userAgent) &&
    /iphone|ipod|ipad/i.test(navigator?.userAgent);

  useOutsideClick(containerRef, () => {
    setIsOpen(false);
  });

  if (isHidden) {
    return null;
  }

  const navItems = isOwner ? ownerNavItems : renterNavItems;

  const renderNavItem = ({
    Icon,
    IconProps,
    to,
    href,
    onClick,
    label,
    id,
    isActiveFilter,
    isInactiveFilter,
  }) => {
    let active = location.pathname.indexOf(isActiveFilter) !== -1;
    if (
      isInactiveFilter &&
      isInactiveFilter
        .split(',')
        .some((route) => location.pathname.indexOf(route) !== -1)
    ) {
      active = false;
    }
    let Element;
    const linkProps = {};

    if (to) {
      // eslint-disable-next-line no-useless-assignment
      Element = Link;
      linkProps.to = to;
    } else if (href) {
      // eslint-disable-next-line no-useless-assignment
      Element = 'a';
      linkProps.href = href;
      linkProps.target = '_blank';
    } else {
      // eslint-disable-next-line no-useless-assignment
      Element = 'span';
    }

    return (
      <Element
        to={to}
        key={`mobile-nav-item-${id}`}
        className={cx(styles.navItem, {
          [styles.active]: active,
        })}
        onClick={() => {
          onClick && onClick();
          setIsOpen(false);
        }}
        {...linkProps}
      >
        {id === 'messages' ? (
          <Badge counter={unreadMessagesCount} className={styles.iconWrap}>
            <Icon
              color={colors.greyLight}
              width={24}
              height={24}
              {...IconProps}
            />
          </Badge>
        ) : (
          <div className={styles.iconWrap}>
            <Icon
              color={colors.greyLight}
              width={24}
              height={24}
              {...IconProps}
            />
          </div>
        )}
        <div className={styles.label}>{label}</div>
      </Element>
    );
  };

  return (
    <>
      {isOwner && <GlobalBanner />}
      <nav className={cx(styles.container, className)} ref={containerRef}>
        <div
          className={cx(styles.navBar, {
            [styles.active]: true,
            [styles.iosChrome]: isIOSChrome,
          })}
        >
          {navItems.map(renderNavItem)}
          {isOwner && (
            <div
              className={cx(styles.navItem, {
                [styles.active]: isOpen,
              })}
              onClick={() => {
                setOpenSidebar(false);
                setIsOpen((state) => !state);
              }}
            >
              <div className={styles.iconWrap}>
                <CloseIcon
                  width={24}
                  height={24}
                  className={cx({ [styles.hidden]: !isOpen })}
                />
                <MoreIcon
                  color={colors.greyLight}
                  width={24}
                  height={24}
                  className={cx({ [styles.hidden]: isOpen })}
                />
              </div>
              <div className={styles.label}>{isOpen ? 'Close' : 'More'}</div>
            </div>
          )}
        </div>
        {isOwner && (
          <div
            className={cx(styles.drawer, {
              [styles.open]: isOpen,
            })}
          >
            {accounts?.length > 1 && currentAccount && (
              <>
                <div
                  className={styles.currentAccount}
                  onClick={() => setOpenSidebar(true)}
                >
                  <ProfilePicture
                    className={styles.profilePicture}
                    size="small"
                    squircle
                    {...currentAccount.IconProps}
                  />
                  <div className={styles.label}>{currentAccount.label}</div>
                  <ArrowRight className={styles.arrow} />
                </div>
                <Divider className={styles.divider} text="" />
              </>
            )}
            {chunk(ownerDrawerItems, OWNER_ITEMS_PER_ROW).map((row, i) => (
              <div className={styles.row} key={`mobile_nav_drawer_row_${i}`}>
                {row.map(renderNavItem)}
              </div>
            ))}
            {isOwner ? (
              <MenuOfferTag
                createdAt={user?.created_at}
                userDataLoaded={userDataLoaded}
                isPremiumUser={user.premium_subscription_subscribed}
                hasAccountingSubscription={user?.reihub_subscription_subscribed}
                onClick={(options) => {
                  const { eventName, path } = options || {};

                  segmentTracking(eventName, {
                    location: 'Nav Bar',
                  });

                  if (path === 'premium') {
                    renderOutside((done) => (
                      <PremiumSubscriptionModal
                        onClose={() => {
                          done();
                        }}
                        onSuccess={() => {
                          done();
                        }}
                        openLocation={MODAL_LOCATIONS.SIDEBAR_NAVIGATION_TAG}
                        refetchQueries={[basicUserProfile, ownerSettingsQuery]}
                      />
                    ));
                  } else {
                    navigate(`${config.PRIVATE_BASE_PATH}${path}`);
                  }
                }}
              />
            ) : null}
          </div>
        )}
        {isOwner && accounts?.length > 1 && (
          <div
            className={cx(styles.sidebar, {
              [styles.open]: openSidebar,
            })}
            onClick={() => setOpenSidebar(false)}
          >
            <div className={styles.title}>Switch Account</div>
            <div>
              {accounts.map(({ label, id, onClick, IconProps, isActive }) => (
                <div
                  className={cx(styles.accountRow, {
                    [styles.active]: isActive,
                  })}
                  key={id}
                  onClick={() => {
                    onClick();
                    setOpenSidebar(false);
                  }}
                >
                  <ProfilePicture
                    className={styles.profilePicture}
                    size="small"
                    squircle
                    {...IconProps}
                  />
                  <div className={styles.label}>{label}</div>
                  {isActive && <CheckFilled className={styles.check} />}
                </div>
              ))}
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

MobileNavigation.propTypes = {
  location: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
  isOwner: PropTypes.bool,
  className: PropTypes.string,
  unreadMessagesCount: PropTypes.number,
  ownerNavItems: PropTypes.array,
  ownerDrawerItems: PropTypes.array,
  renterNavItems: PropTypes.array,
  user: PropTypes.object,
  userDataLoaded: PropTypes.bool,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      IconProps: PropTypes.object,
      onClick: PropTypes.func,
      isActive: PropTypes.bool,
    }),
  ),
  currentAccount: PropTypes.shape({
    label: PropTypes.string,
    IconProps: PropTypes.object,
  }),
};

export default MobileNavigation;
