import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import AccountBlocklisted from '../../components/AccountBlocklistedModal';
import Page from '../../components/page';
import PageNotFound from '../../components/PageNotFound';
import { useConfig } from '../../providers/ConfigProvider';
import RenterSMSExternalApplication from '../renters/common/RenterSMSExternalApplication';

import LogoutComponent from './login/LogoutComponent';
import OwnerLoginPage from './login/OwnerLoginPage';
import RenterLoginCard from './login/RenterLoginCard';
import ResetPasswordComponent from './login/ResetPasswordContainer';
import SetPasswordPage from './login/SetPasswordPage';
import AziboWelcome from './set_password/AziboWelcome';
import NewTenantSetPassword from './set_password/NewTenantSetPassword/NewTenantSetPassword';
import AcademySignupPage from './signup/AcademySignupPage';
import ConfirmEmailComponent from './signup/ConfirmEmailContainer';
import ForumSignupPage from './signup/ForumSignupPage';
import AppSumoSignupPage from './signup/partners/AppSumo/SignupPage';
import SignupPage from './signup/SignupPage';
import PersonalizeExperiencePage from './signupProExperiment/PersonalizeExperiencePage';
import SignupPageExperiment from './signupProExperiment/SignupPageExperiment';
import UserRoutes from './user/routes.user';
import ConfirmDevice from './ConfirmDevice';
import NotRecognizedDevice from './NotRecognizedDevice';
import SelectAccount from './SelectAccount';

const AuthRoutes = ({ match, location }) => {
  const { IS_OWNER } = useConfig();
  const me = match.url;

  const { navigate } = useNavigate();
  const { action, redirectUrl } = queryString.parse(location.search);
  if (action === 'signup' && location.pathname.indexOf('/login') !== -1) {
    if (
      !(redirectUrl || '').startsWith(
        'https%3A%2F%2Facademy.turbotenant.com%2Fpayment%3Fproduct_id%3D',
      )
    ) {
      // TODO HACK, THIS IS TO AVOID ACADEMY REDIRECTION TO SIGNUP WHEN CLICKING "ENROLL"
      navigate(
        `${location.pathname.replace(
          '/login',
          '/signup',
        )}?redirectUrl=${encodeURIComponent(redirectUrl)}&type=landlord`,
        {
          replace: true,
        },
      );
    }
  }
  return (
    <Switch>
      <Route
        exact
        path={`${me}/login`}
        render={(props) => (
          <Page
            title={`${IS_OWNER ? 'Landlord' : 'Renter'} Login - TurboTenant`}
          >
            {IS_OWNER ? (
              <OwnerLoginPage {...props} />
            ) : (
              <RenterLoginCard {...props} />
            )}
          </Page>
        )}
      />
      {IS_OWNER && (
        <Route
          path="/auth/signup/final/:subscription"
          render={({ match, ...props }) => (
            <Page title="Landlord Sign Up - TurboTenant">
              <SignupPageExperiment
                plan={match.params.subscription}
                {...props}
              />
            </Page>
          )}
        />
      )}
      {IS_OWNER && (
        <Route
          path="/auth/signup/personalize/:plan"
          render={({ match, ...props }) => (
            <Page title="Landlord Sign Up - TurboTenant">
              <PersonalizeExperiencePage plan={match.params.plan} {...props} />
            </Page>
          )}
        />
      )}
      {IS_OWNER && (
        <Route
          path={`${me}/signup`}
          render={(props) => (
            <Page title="Landlord Sign Up - TurboTenant">
              <SignupPage {...props} />
            </Page>
          )}
        />
      )}

      {IS_OWNER && (
        <Route
          exact
          path={`${me}/academy/login`}
          render={(props) => (
            <Page title="Academy Login - TurboTenant">
              <OwnerLoginPage {...props} />
            </Page>
          )}
        />
      )}

      {IS_OWNER && (
        <Route
          exact
          path={`${me}/academy/signup`}
          render={(props) => (
            <Page title="Academy Sign Up - TurboTenant">
              <AcademySignupPage {...props} />
            </Page>
          )}
        />
      )}
      {IS_OWNER && (
        <Route
          exact
          path={`${me}/forum/login`}
          render={(props) => (
            <Page title="Be Better Landlords Forum Login - TurboTenant">
              <OwnerLoginPage {...props} />
            </Page>
          )}
        />
      )}
      {IS_OWNER && (
        <Route
          exact
          path={`${me}/forum/signup`}
          render={(props) => (
            <Page title="Be Better Landlords Forum Sign Up - TurboTenant">
              <ForumSignupPage {...props} />
            </Page>
          )}
        />
      )}
      {IS_OWNER && (
        <Route
          exact
          path={`${me}/appsumo`}
          render={(props) => (
            <Page title="Landlord Sign Up - TurboTenant">
              <AppSumoSignupPage {...props} />
            </Page>
          )}
        />
      )}
      {IS_OWNER && (
        <Route
          exact
          path={`${me}/notrecognized`}
          component={NotRecognizedDevice}
        />
      )}
      <Route exact path={`${me}/select-account`} component={SelectAccount} />
      <Route exact path={`${me}/blocklisted`} component={AccountBlocklisted} />
      <Route
        exact
        path={`${me}/confirm_email/:token`}
        component={ConfirmEmailComponent}
      />
      <Route exact path={`${me}/logout`} component={LogoutComponent} />
      <Route exact path={`${me}/azibo/setpassword`} component={AziboWelcome} />
      <Route exact path={`${me}/setpassword`} component={SetPasswordPage} />
      {!IS_OWNER && (
        <Route
          exact
          path={`${me}/tenant/setpassword`}
          component={NewTenantSetPassword}
        />
      )}
      <Route
        exact
        path={`${me}/resetpassword/:type`}
        component={ResetPasswordComponent}
      />

      <Route
        exact
        path={`${me}/academy/resetpassword/:type`}
        component={(props) => (
          <ResetPasswordComponent {...props} academy={true} />
        )}
      />
      <Route
        exact
        path={`${me}/forum/resetpassword/:type`}
        component={(props) => (
          <ResetPasswordComponent {...props} forum={true} />
        )}
      />

      <Route
        exact
        path={`${me}/externalapplicationsms/intro`}
        component={RenterSMSExternalApplication}
      />
      <Route
        exact
        path={`${me}/confirm-device/:confirmationToken`}
        component={ConfirmDevice}
      />
      <Route
        path={`${me}/user`}
        render={(props) => <UserRoutes {...props} />}
      />
      <Route component={() => <PageNotFound actionTitle="Back Home" />} />
    </Switch>
  );
};

AuthRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      subscription: PropTypes.string,
      plan: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AuthRoutes;
