import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Icon } from '@tt/ui';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../core/TTgraphql';
import basicUserProfile from '../../graphql/basicUserProfile.graphql';
import updateOnBoardingInfoMutation from '../../graphql/mutations/updateOnBoardingInfo.graphql';
import { segmentTracking } from '../../services/utilities';
import Button from '../Button';
import Modal from '../Modal';
import { useErrorToast, useSuccessToast } from '../Toast';

import styles from './EditRentalCountModal.module.scss';

const EditRentalCountModal = ({ onClose = () => {} }) => {
  const { user } = useUserProfile();
  const showErrorToast = useErrorToast();
  const showSuccessToast = useSuccessToast();
  const initialCount = user?.onboarding_data?.properties || 0;
  const [count, setCount] = useState(initialCount);

  const [updateOnBoardingInfo, { loading }] = useMutation(
    updateOnBoardingInfoMutation,
    {
      refetchQueries: [{ query: basicUserProfile }],
    },
  );

  const handleIncrement = () => {
    setCount((prev) => prev + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount((prev) => prev - 1);
    }
  };

  const handleSubmit = async () => {
    segmentTracking('edit_rental_count_modal submitted', {
      location: 'edit_rental_count_modal',
      properties_count: count,
    });
    try {
      await updateOnBoardingInfo({
        variables: {
          onboarding_data: {
            properties: count,
          },
        },
      });
      onClose();
      showSuccessToast('Property count updated successfully');
    } catch (error) {
      console.log('error', error);
      showErrorToast('Failed to update property count');
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      showCloseIcon
    >
      <div className={styles.container}>
        <h2 className={styles.title}>Edit your rental count</h2>
        <p className={styles.subtitle}>How many units do you own/manage?</p>

        <div className={styles.inputGroup}>
          <button
            type="button"
            onClick={handleDecrement}
            className={styles.iconButton}
            disabled={count === 0}
          >
            <Icon name="minus" color="primary" />
          </button>

          <input
            type="number"
            value={count}
            onChange={(e) => setCount(parseInt(e.target.value) || 0)}
            className={styles.input}
          />

          <button
            type="button"
            onClick={handleIncrement}
            className={styles.iconButton}
          >
            <Icon name="plus" color="primary" />
          </button>
        </div>

        <Button
          onClick={handleSubmit}
          className={styles.updateButton}
          fullWidth
          disabled={loading}
          loading={loading}
        >
          UPDATE
        </Button>
      </div>
    </Modal>
  );
};

EditRentalCountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default EditRentalCountModal;
