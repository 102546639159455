import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

import styles from './CircularProgress.module.scss';

const CircularProgress = ({
  percentage = 34,
  color = colors.slimProgressBarFill,
  icon,
  className,
}) => {
  const radius = 32;
  const strokeWidth = 6;
  const adjustedRadius = radius - strokeWidth / 2;
  const circleLength = adjustedRadius * 2 * Math.PI;
  const progressOffset = circleLength - (percentage / 100) * circleLength;

  return (
    <div className={cx(styles.circularProgress, className)}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke={colors.grayLight}
          fill="transparent"
          strokeWidth={strokeWidth}
          r={adjustedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={color}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circleLength} ${circleLength}`}
          strokeDashoffset={progressOffset}
          r={adjustedRadius}
          cx={radius}
          cy={radius}
          className={styles.progressRing}
        />
      </svg>
      <div className={styles.icon}>{icon}</div>
    </div>
  );
};

CircularProgress.propTypes = {
  percentage: PropTypes.number,
  color: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
};

export default CircularProgress;
