import React, { useEffect, useState } from 'react';
import { Icon } from '@tt/ui';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import PremiumBanner from '../../../assets/illustrations/PremiumBanner';
import colors from '../../../constants/colors';
import { useRentPaymentRules } from '../../../hooks/useRentPaymentRules';
import Button from '../../Button';
import Skeleton from '../../Skeleton';

import styles from './MenuOfferTag.module.scss';

const CTA_OPTIONS = {
  GET_PAID: {
    id: 'GET_PAID',
    badgeText: 'Get Paid',
    mobileSuffix: '',
    iconName: 'cash',
    path: 'payments/cash',
    segmentEvent: 'get_paid clicked',
  },
  UNLOCK_INSIGHTS: {
    id: 'UNLOCK_INSIGHTS',
    badgeText: 'Unlock Insights',
    mobileSuffix: '',
    iconName: 'reporting',
    path: 'payments/accounting',
    segmentEvent: 'unlock_insights clicked',
  },
  GIVE_25: {
    id: 'GIVE_25',
    badgeText: 'Give $25. Get $25.',
    mobileSuffix: '',
    iconName: 'refer',
    path: 'referrals',
    segmentEvent: 'give_25 clicked',
  },
};

const LOCAL_STORAGE_KEY = 'menu_offer_tag_last_cta';

const MenuOfferTag = ({
  className,
  onClick,
  createdAt,
  userDataLoaded,
  isPremiumUser,
  hasAccountingSubscription,
}) => {
  const accountOlderThanThirtyDays =
    moment().diff(moment(createdAt), 'days') > 30;
  const { rentPaymentsStatus } = useRentPaymentRules();
  const hasSetupRentPayments = rentPaymentsStatus.hasOwnerSetupRentPayments;

  const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
  const parsedData = storedData ? JSON.parse(storedData) : null;

  const [currentCTA, setCurrentCTA] = useState(
    CTA_OPTIONS[parsedData?.ctaId] || null,
  );

  useEffect(() => {
    if (!isPremiumUser) return;

    try {
      // Get the last shown CTA and date from localStorage
      const today = moment().format('YYYY-MM-DD');

      // If we have stored data and it's from today, use that CTA
      if (parsedData && moment(parsedData.date).isSame(today, 'day')) {
        // Check if the stored CTA is still eligible
        const ctaId = parsedData.ctaId;
        const cta = CTA_OPTIONS[ctaId];

        if (cta) {
          const isIneligibleCTA =
            (ctaId === 'UNLOCK_INSIGHTS' && hasAccountingSubscription) ||
            (ctaId === 'GET_PAID' && hasSetupRentPayments);

          if (!isIneligibleCTA) {
            setCurrentCTA(cta);
            return;
          }
        }
      }

      // Otherwise, determine which CTAs the user is eligible for
      const eligibleCTAs = [CTA_OPTIONS.GIVE_25];

      if (!hasSetupRentPayments) {
        eligibleCTAs.push(CTA_OPTIONS.GET_PAID);
      }

      if (!hasAccountingSubscription) {
        eligibleCTAs.push(CTA_OPTIONS.UNLOCK_INSIGHTS);
      }

      // Get the previous CTA ID to avoid showing the same one consecutively
      const prevCTAId = parsedData?.ctaId;

      // Filter out the previous CTA if there are multiple options
      const filteredCTAs =
        eligibleCTAs.length > 1 && prevCTAId
          ? eligibleCTAs.filter((cta) => cta.id !== prevCTAId)
          : eligibleCTAs;

      // Select a random CTA from the eligible options
      const nextCTA =
        filteredCTAs[Math.floor(Math.random() * filteredCTAs.length)];

      // Store the selected CTA ID and today's date
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          ctaId: nextCTA.id,
          date: today,
        }),
      );

      setCurrentCTA(nextCTA);
    } catch (_error) {
      // Fallback to GIVE_25 if there's any error
      setCurrentCTA(CTA_OPTIONS.GIVE_25);
    }
  }, [isPremiumUser, hasSetupRentPayments, hasAccountingSubscription]);

  if (userDataLoaded) {
    return (
      <Skeleton>
        <Skeleton.Button dark size="sidebar" />
      </Skeleton>
    );
  }

  // For non-premium users, show the upgrade CTA
  if (!isPremiumUser) {
    return (
      <Button
        className={cx(styles.container, className, {
          [styles.blue]: accountOlderThanThirtyDays,
        })}
        onClick={() => {
          onClick({
            eventName: 'upgrade clicked',
            path: 'premium',
          });
        }}
        dataQa="menu-offer-tag"
      >
        <PremiumBanner
          className={styles.icon}
          color={colors.primary}
          starColor={colors.warning}
          height={24}
          width={17}
        />
        <span className={styles.label}>
          Upgrade <span className={styles.mobileSuffix}>to Premium</span>
        </span>
      </Button>
    );
  }

  // For premium users, show the appropriate CTA based on rotation
  const cta = currentCTA || CTA_OPTIONS.GIVE_25;

  return (
    <Button
      className={cx(styles.container, className, styles.blue)}
      onClick={() => {
        onClick({
          eventName: cta.segmentEvent,
          path: cta.path,
        });
      }}
      dataQa="menu-offer-tag"
    >
      <Icon
        name={cta.iconName}
        className={styles.icon}
        color="reverse"
        size={24}
      />
      <span className={styles.label}>
        {cta.badgeText}{' '}
        <span className={styles.mobileSuffix}>{cta.mobileSuffix}</span>
      </span>
    </Button>
  );
};

MenuOfferTag.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  createdAt: PropTypes.string,
  userDataLoaded: PropTypes.bool,
  isPremiumUser: PropTypes.bool,
  hasAccountingSubscription: PropTypes.bool,
};

export default MenuOfferTag;
