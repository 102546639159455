import React, { useEffect, useRef, useState } from 'react';
import useWindowSize from '@rooks/use-window-size';
import { MultiColorIcon } from '@tt/ui';
import PropTypes from 'prop-types';

import { Experiments } from '../../constants/experiments';
import { smallScreenWidth } from '../../constants/media-breakpoints';
import { useUserProfile } from '../../core/TTgraphql';
import getExperimentVariant from '../../helpers/experiments';
import { segmentTracking } from '../../services/utilities';
import Button from '../Button';
import CircularProgress from '../CircularProgress/CircularProgress';
import EditRentalCountModal from '../EditRentalCountModal';

import styles from './PropertiesHeaderWidget.module.scss';

const PropertiesHeaderWidget = ({ className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useUserProfile();
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth <= smallScreenWidth;
  const hasTrackedMount = useRef(false);

  const finishAddingRentalsExperimentVariant = getExperimentVariant(
    user,
    Experiments.GT_FINISH_ADDING_RENTALS_CTA.name,
  );

  const isFinishAddingRentalsExperiment =
    finishAddingRentalsExperimentVariant ===
    Experiments.GT_FINISH_ADDING_RENTALS_CTA.variants.variant_1;

  const reportedProperties = user?.onboarding_data?.properties;
  const leasableListingsCount = user?.leasable_listings_count || 0;
  const percentage = (leasableListingsCount / reportedProperties) * 100;
  const hasLessThan90Percent = percentage < 90;

  useEffect(() => {
    if (
      isFinishAddingRentalsExperiment &&
      hasLessThan90Percent &&
      !hasTrackedMount.current
    ) {
      segmentTracking('properties_header_widget mounted');
      hasTrackedMount.current = true;
    }
  }, [isFinishAddingRentalsExperiment, hasLessThan90Percent]);

  const handleOpenModal = () => {
    segmentTracking('properties_header_widget open_modal', {
      location: 'properties_header_widget',
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    segmentTracking('properties_header_widget close_modal', {
      location: 'properties_header_widget',
    });
    setIsModalOpen(false);
  };

  const onClick = () => {
    segmentTracking('add_a_new_property clicked ', {
      location: 'properties_header_widget',
    });
  };

  if (!isFinishAddingRentalsExperiment || !hasLessThan90Percent) {
    return null;
  }

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.innerContainer}>
        <div className={styles.leftCol}>
          <CircularProgress
            percentage={percentage}
            icon={<MultiColorIcon name="property" size="sm" />}
            className={styles.circularProgress}
          />
          <div className={styles.texts}>
            <div className={styles.title}>Properties</div>
            <div className={styles.divider} />
            <div className={styles.subtitle}>
              {leasableListingsCount} of{' '}
              <span
                className={styles.units}
                onClick={handleOpenModal}
                role="button"
                tabIndex={0}
              >
                {reportedProperties} units
              </span>{' '}
              added ({Math.round(percentage)}%).
            </div>
          </div>
        </div>
        <div className={styles.rightCol}>
          <Button
            variant="primary"
            size="small"
            to="/owners/properties/create"
            onClick={onClick}
          >
            {isMobile ? 'Add New' : 'Add New Property'}
          </Button>
        </div>

        {isModalOpen && (
          <EditRentalCountModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

PropertiesHeaderWidget.propTypes = {
  className: PropTypes.string,
};

export default PropertiesHeaderWidget;
