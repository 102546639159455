import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import { colors as tagColors, types } from './constants';

import styles from './Tag.module.scss';

const Tag = ({ color = 'navyBlue', children, type = 'default', className }) => {
  return (
    <div
      className={cx(
        styles.container,
        styles[`color-${color}`],
        styles[`type-${type}`],
        className,
      )}
    >
      {children}
      {type === 'dropdown' && (
        <Icon name="arrow-down" className={styles.caret} />
      )}
    </div>
  );
};

Tag.propTypes = {
  color: PropTypes.oneOf(tagColors),
  type: PropTypes.oneOf(types),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Tag;
