import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';

import Button from '../../../../components/Button';
import FinalFormError from '../../../../components/FinalFormError';
import PasswordInput from '../../../../components/PasswordInput';
import SidebarMarketing from '../../../../components/SidebarMarketing';
import { H2, Link, Paragraph } from '../../../../components/Typography';
import { login } from '../../../../core/auth/authService';
import verifyConfirmationToken from '../../../../core/services/tokenService';
import MarketingLayout from '../../../../layout/MarketingLayout';
import { useConfig } from '../../../../providers/ConfigProvider';
import comparePassword from '../../../../validators/comparePassword';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validPasswordStrength from '../../../../validators/validPasswordStrength';
import confirmAccountGQL from '../confirmAccount.graphql';

import SidebarImageOwner from './graphic-landlord.svg';
import SidebarImageRenter from './graphic-renter.svg';

import styles from './AziboWelcome.module.scss';

const AziboWelcome = () => {
  const { IS_OWNER, PRIVATE_BASE_PATH } = useConfig();
  const navigate = useNavigate();

  const { token, confirmationToken } = queryString.parse(location.search);
  const [confirmAccountRequest, { loading: confirmingAccount }] =
    useMutation(confirmAccountGQL);

  const confirmAccountAndLogin = async ({ password }) => {
    try {
      const response = await confirmAccountRequest({
        variables: {
          password,
          token: confirmationToken,
        },
      });
      const data = response?.data?.confirmAccount;
      login(data.token);
      navigate(`${PRIVATE_BASE_PATH}dashboard?aziboOnboarding=true`);
    } catch (error) {
      console.error(error);
    }
  };

  const checkTokenValidity = async () => {
    if (!token && !confirmationToken) {
      return navigate('/auth/login', { replace: true });
    }

    try {
      const response = await verifyConfirmationToken(
        `verify?accountType=${IS_OWNER ? '1' : '2'}&${
          token ? `jwtToken=${token}` : `confirmationToken=${confirmationToken}`
        }`,
      );

      if (!response || response.has_password_set)
        return navigate('/auth/login', { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkTokenValidity();
  }, []);

  const Sidebar = IS_OWNER ? (
    <SidebarMarketing
      className={styles.sidebar}
      title="rental management"
      subtitle="Change can be stressful, but we’re here to help"
      description={
        <Paragraph className={styles.description}>
          Our dedicated, Colorado-based support team is here for you and your
          tenants 7 days a week. We want to make this transition as smooth as
          possible.
        </Paragraph>
      }
      graphic={SidebarImageOwner}
    />
  ) : (
    <SidebarMarketing
      className={styles.sidebar}
      title="your new tenant portal"
      subtitle="Renting made easy"
      description={
        <Paragraph className={styles.description}>
          From helping you ensure rent is paid on time to monitoring
          maintenance, we simplify the renting process for tenants and landlords
          nationwide.
        </Paragraph>
      }
      graphic={SidebarImageRenter}
    />
  );

  return (
    <MarketingLayout hideMobileFooter side={Sidebar}>
      <div className={styles.container}>
        <Form
          onSubmit={confirmAccountAndLogin}
          subscription={{
            submitting: true,
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <H2>
                Welcome to TurboTenant! Azibo is closing it’s doors, but we have
                you covered.
              </H2>
              <Paragraph className={styles.formDescription}>
                Let’s start by setting your password.
              </Paragraph>
              <FinalFormError />
              <Field
                label="New Password"
                component={PasswordInput}
                name="password"
                id="password"
                inputProps={{
                  autoComplete: 'new-password',
                }}
                passwordStrengthClassName={styles.mb16}
                showPasswordStrengthChecker
                validate={validPasswordStrength}
              />
              <Field
                label="Confirm Password"
                component={PasswordInput}
                className={styles.field}
                name="passwordConfirm"
                id="passwordConfirm"
                validate={composeValidators(
                  fieldRequired,
                  comparePassword('password'),
                )}
                hint={null}
                inputProps={{
                  autoComplete: 'password',
                }}
              />

              <div className={styles.btnCont}>
                <Button
                  className={styles.submitBtn}
                  type="submit"
                  loading={submitting || confirmingAccount}
                  id="new-tenant-set-password"
                >
                  Set Password
                </Button>
              </div>
            </form>
          )}
        </Form>
        <div className={styles.termsText}>
          By clicking the button above you are agreeing to our{<br />}{' '}
          <Link
            to="/https://www.turbotenant.com/terms-of-use/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </Link>{' '}
          &{' '}
          <Link
            to="https://www.turbotenant.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          .
        </div>
      </div>
    </MarketingLayout>
  );
};

export default AziboWelcome;
